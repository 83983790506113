import React from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getUser, removeUserSession} from "./Common";

function NavbarPage() {
    const user = getUser();
    const handleClose = async () => {
        removeUserSession();
        window.location.assign("https://myuzb.uz");
        // window.location.assign("http://localhost:3000");

    }

    return (
        <>
            <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" fixed='top'>
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Brand as={Link} to="/">Home</Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/history">History</Nav.Link>
                                <Nav.Link as={Link} to="/clients">Kontragentlar</Nav.Link>
                                <Nav.Link as={Link} to="/dostuplar">DOSTUP</Nav.Link>


                                <NavDropdown title="ПРОФИЛ" id="profile">
                                    {/*<NavDropdown.Item as={Link} to="/sozlamalar">Созлама</NavDropdown.Item>*/}
                                    {/*<NavDropdown.Item as={Link} to="/rekvizit">Реквизит</NavDropdown.Item>*/}
                                    {/*{user.rol === 9 ? <NavDropdown.Item as={Link} to="/admin-panel">Админ панел</NavDropdown.Item>:null}*/}
                                    <NavDropdown.Item onClick={() => handleClose()}>Chiqish</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                    </Navbar.Collapse>
                    <Navbar.Text>{user !== null ? user.username : null}</Navbar.Text>
                </Container>
            </Navbar>
            <br/>
            <br/>
            <br/>
        </>
    );
}

export default NavbarPage;

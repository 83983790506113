import React, { useState } from 'react';
import axios from "axios";
import { removeUserSession, setUserSession } from "../Components/Common";
import Swal from "sweetalert2";


function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        setLoading(true);
        await axios.post('login', { username, password })
            .then(result => {
                axios.defaults.headers.common['token'] = result.data.token;
                setUserSession(result.data.token, result.data.user);
                window.location.reload();
            }).catch(err => {
                removeUserSession();
                console.log(err.response);
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'XATO!',
                    text: err.response,
                    showConfirmButton: false,
                    timer: 2500
                });
            })
        setLoading(false);
    }
    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-sm-3 shadow-lg rounded-3 alert alert-primary border-success">

                        <div className="row mb-3 mt-3">
                            <label htmlFor="username" className="col-sm-3 form-label">Login</label>
                            <div className="col-sm-12">
                                <input type="text" className="form-control" id="username"
                                    value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="password" className="col-sm-3 form-label">Password</label>
                            <div className="col-sm-12">
                                <input type="password" className="form-control" value={password}
                                    onChange={(e) => setPassword(e.target.value)} id="password" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="mb-3 btn btn-primary"
                                    onClick={() => handleSubmit()}>Kirish
                                </button>
                            </div>
                        </div>

                        {loading ? (
                            <div className="d-sm-flex justify-content-center">
                                <div className="spinner-grow text-primary" role="status"></div>
                            </div>
                        ) : ('')}

                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginPage;

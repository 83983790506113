import React from 'react';
import {createRoot} from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from './App';
import axios from 'axios';


// axios.defaults.baseURL = 'http://localhost:5005/api/';
axios.defaults.baseURL = 'https://myuzb.uz/api/';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App/>);

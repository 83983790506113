import {BrowserRouter, Routes, Route} from 'react-router-dom';
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import NavbarPage from "./Components/NavbarPage";
import {getToken, getUser} from "./Components/Common";
import LoginPage from "./Login/LoginPage";
import HomePage from "./Pages/HomePage";
import HistoryPages from "./Pages/HistoryPages";
import Clients from "./Pages/Clients.jsx";
import DostupLists from "./Pages/DostupLists";


axios.defaults.headers.common['token'] = getToken();

function App() {
    const loggedTrue = () => {
        return (
            <BrowserRouter>
                <NavbarPage />
                <Routes>
                    <Route exact path="/" element={<HomePage/>}/>
                    <Route exact path="/history" element={<HistoryPages/>}/>
                    <Route exact path="/clients" element={<Clients/>}/>
                    <Route exact path="/dostuplar" element={<DostupLists/>}/>
                    <Route path="*" element={<HomePage/>}/>
                </Routes>
            </BrowserRouter>
        );
    }

    const loggedFalse = () => {
        return (
            <BrowserRouter>
                <Routes>
                    <Route exact path="/login" element={<LoginPage/>}/>
                    <Route path="*" element={<LoginPage/>}/>
                    <Route/>
                </Routes>
            </BrowserRouter>
        );
    }

    return (
        <>
            {
                getUser() !== null ? loggedTrue() : loggedFalse()
            }
        </>
    );
}

export default App;

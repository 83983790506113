import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import {paginate} from "../Components/paginate";
import Pagination from "../Components/pagination";
import {Zoom} from 'react-awesome-reveal';
import Container from "@mui/material/Container";
import {getUser} from "../Components/Common";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {Button, Modal} from "react-bootstrap";


const pageSize = 10;
const user = getUser();

//Yil boshi
// let today = new Date();
// let currentYear = today.getFullYear();
// let yilBoshi = new Date(currentYear, 0, 1, 0, 0, 0);
// let yilOxiri = new Date(currentYear, 11, 31, 23, 59, 59);

function Clients() {
    const [items, setItems] = useState([]);

    const [id, setId] = useState(0);
    const [kontragent, setKontragent] = useState('');
    const [inn, setInn] = useState('');
    const [yil, setYil] = useState('');
    const [izoh, setIzoh] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [qidirSuz, setQidirSuz] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isNew, setIsNew] = useState(true);
    const inputRef = useRef(null);

    useEffect(() => {
        setLoading(true);

        async function start() {
            await getItemsRuyxati();
        }

        start().then();
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    const getItemsRuyxati = async () => {
        setLoading(true);
        await axios.get('kontragentlar-all')
            .then((result) => {
                setItems(result.data);
            })
            .catch((err) => {
                if (err.response.status === 481) {
                    Swal.fire('XATO!', err.response.data.message, 'error');
                } else if (err.response.status === 450) {
                    Swal.fire('XATO!', err.response.data.message, 'error');
                } else {
                    console.log(err.response);
                    Swal.fire('XATO!', 'Хатолик мавжуд. Админга хабар беринг!', 'error');
                }
                setLoading(false);
            })
        setLoading(false);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleADDItem = async () => {
        await setIsNew(true);
        await setId(0);
        await setKontragent('');
        await setInn('');
        await setYil('');
        await setIzoh('');
        await setIsOpen(true);
    }

    const handleEditItem = async (elmObj) => {
        await setIsNew(false);
        await setId(elmObj.id);
        await setKontragent(elmObj.name);
        await setInn(elmObj.inn);
        await setYil(elmObj.yil);
        await setIzoh(elmObj.izoh);
        await setIsOpen(true);
    }

    const handleDelItem = async (id_) => {
        await Swal.fire({
            title: 'Контрагентни ўчириш',
            text: 'Контрагентни ўчирасизми?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ҳа ўчираман!',
            cancelButtonText: 'Бекор'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.get('kontragent-del/' + id_)
                    .then(() => {
                        Swal.fire(
                            'Ўчирилди!',
                            'Танланган контрагент ўчирилди!',
                            'success'
                        )
                        let updatedItems = [...items].filter(i => i.id !== id_);
                        setItems(updatedItems);
                    })
                    .catch((err) => {
                        if (err.response.status === 481) {
                            Swal.fire('XATO!', err.response.data.message, 'error');
                        } else if (err.response.status === 450) {
                            Swal.fire('XATO!', err.response.data.message, 'error');
                        } else {
                            console.log(err.response);
                            Swal.fire('XATO!', 'Хатолик мавжуд. Админга хабар беринг!', 'error');
                        }
                    })
            }
        })
    }

    const handleModalClose = () => {
        setIsOpen(false);
    }

    const handleSubmitModal = async () => {

        if (kontragent.length < 3) {
            await Swal.fire('XATO!', "Контрагент номи камида 3та харфдан иборат бўлиши керак!", 'error');
            return;
        }

        if (inn.toString().length !== 9) {
            await Swal.fire('XATO!', "ИНН хато киритилган!", 'error');
            return;
        }

        if (yil.toString().length !== 4) {
            await Swal.fire('XATO!', "Йил хато киритилган!", 'error');
            return;
        }

        const dataNew = {name: kontragent, inn, yil, izoh};
        const dataEdit = {id, name: kontragent, inn, yil, izoh};

        if (isNew) {
            await axios.post('kontragent-add', dataNew)
                .then((result) => {
                    const obj = {...dataNew, id: result.data.id};
                    const updatedItemsList = [...items, obj];
                    const dataSortedItems = updatedItemsList.sort(function (a, b) {
                        return b.name - a.name;
                    });
                    setItems(dataSortedItems);

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Контрагент қўшилди!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setIsOpen(false);
                }).catch((err) => {
                    if (err.response.status === 481) {
                        Swal.fire('XATO!', err.response.data.message, 'error');
                    } else if (err.response.status === 450) {
                        Swal.fire('XATO!', err.response.data.message, 'error');
                    } else {
                        Swal.fire('XATO!', 'Админга хабар беринг!', 'error');
                        console.log(err.response);
                    }
                })
        } else {
            await axios.post('kontragent-edit', dataEdit).then(() => {
                const updatedItems = [...items].filter(i => i.id !== dataEdit.id);
                const updatedItemsList = [...updatedItems, dataEdit];
                const dataSortedItems = updatedItemsList.sort(function (a, b) {
                    return b.name - a.name;
                });
                setItems(dataSortedItems);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Muofaqiyatli bajarildi!',
                    showConfirmButton: false,
                    timer: 1500
                });
                setIsOpen(false);
            }).catch((err) => {
                if (err.response.status === 481) {
                    Swal.fire('XATO!', err.response.data.message, 'error');
                } else if (err.response.status === 450) {
                    Swal.fire('XATO!', err.response.data.message, 'error');
                } else {
                    Swal.fire('XATO!', 'Админга хабар беринг!', 'error');
                    console.log(err.response);
                }
            })
        }
    }

    ////////////////////////////////SEARCH AND PAGINATED///////////////////////////////////////////////////////

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const dataSearched = items.filter((el) => {
        if (qidirSuz === "") {
            return true
        } else {
            return (el.name.toLowerCase().includes(qidirSuz.toLowerCase()) || el.inn.toString().includes(qidirSuz.toLowerCase()))
        }
    });

    const count = dataSearched.length;
    const paginated = paginate(dataSearched, currentPage, pageSize);
    const rowNumber = (currentPage - 1) * pageSize;

    return (
        <>
            <Zoom>
                <Container component="main" maxWidth="lg" className="shadow-lg rounded-3 pt-3">
                    <div className="row alert alert-danger p-2">
                        <div className="col-sm-12 text-sm-left">
                            <h5>Клиентлар рўйхати</h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-end">

                            <input className="form-control-sm btn-outline-info mb-1 ms-1" type="search"
                                   onChange={(event) => {
                                       setQidirSuz(event.target.value);
                                       setCurrentPage(1);
                                   }} placeholder="Search - Контрагент ёки ИНН"/>

                            {
                                user.rol === 9 ?
                                    <input type="submit" onClick={() => handleADDItem()} title="Янги контрагент қўшиш"
                                           className="form-control-sm btn btn-outline-info btn-sm mb-1 ms-1"
                                           value="ADD"/> : null
                            }

                        </div>
                    </div>

                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-grow text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : ('')}

                    <div className="row mt-2">
                        <div className="col-12">
                            <table className="table table-hover table-bordered table-responsive d-md-table">
                                <thead className="alert alert-success">
                                <tr className="text-center">
                                    <th className="align-middle">№</th>
                                    <th className="align-middle">ИД</th>
                                    <th className="align-middle">Контрагент</th>
                                    <th className="align-middle">ИНН</th>
                                    <th className="align-middle">Йил</th>
                                    <th className="align-middle">Изоҳ</th>
                                    <th className="align-middle">Амаллар</th>
                                </tr>
                                </thead>
                                <tbody style={{fontSize: 12}}>
                                {
                                    paginated.map((el, i) => (
                                        <tr key={i}>
                                            <td className="text-center align-middle">{rowNumber + i + 1}</td>
                                            <td className="text-center align-middle">{el.id}</td>
                                            <td className="text-start align-middle">{el.name}</td>
                                            <td className="text-center align-middle">{el.inn}</td>
                                            <td className="text-center align-middle">{el.yil}</td>
                                            <td className="text-center align-middle">{el.izoh}</td>
                                            <td className="text-center align-middle">
                                                <div className="btn-group" role="group">
                                                    <IconButton disabled={user.rol < 9}
                                                                onClick={() => handleEditItem(el)}
                                                                size="small"
                                                                title="Узгартириш"><EditIcon
                                                        color="primary"
                                                        fontSize="small"/></IconButton>

                                                    <IconButton disabled={user.rol < 9}
                                                                onClick={() => handleDelItem(el.id)}
                                                                title="Учириш"
                                                                size="small"><DeleteIcon color="primary"
                                                                                         fontSize="small"/></IconButton>
                                                </div>
                                            </td>

                                        </tr>
                                    ))
                                }
                                </tbody>
                                <tfoot className="alert-success">
                                <tr className="text-center font-weight-bold">
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                                </tfoot>
                            </table>
                            <Pagination
                                countItems={count}
                                pageSize={pageSize}
                                onPageChange={handlePageChange}
                                currentPage={currentPage}
                            />
                        </div>
                    </div>
                </Container>
            </Zoom>

            <Modal
                show={isOpen}
                onHide={handleModalClose}
                keyboard={false}
                backdrop="static"
                onEntered={() => inputRef.current.focus()}
                centered
                size="md"
            >
                <Modal.Header closeButton className="alert-info">
                    <Modal.Title>{isNew ? 'New kontragent' : 'Edit kontragent'}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="alert alert-success mb-0">
                    <div className="row input-group">
                        <div className="col-12">
                            <label className="form-label mt-2">Контрагент номи: <span
                                className="text-danger">*</span>
                            </label>
                            <input type="text" value={kontragent} ref={inputRef} className="form-control py-1"
                                   onChange={(e) => setKontragent(e.target.value)} name="kontragent"
                                   placeholder="Контрагент номини киритинг (мин: 6 та харф)"/>
                        </div>
                    </div>
                    <br/>

                    <div className="row">
                        <div className="col-8">
                            <label className="form-label mb-1">ИНН: <span className="text-danger">*</span> </label><br/>
                            <input type="number" max={999999999} value={inn} className="form-control py-1"
                                   onChange={(e) => setInn(e.target.value)} name="inn"/>
                        </div>

                        <div className="col-4">
                            <label className="form-label mb-1">ЙИЛ: <span className="text-danger">*</span></label>
                            <input type="number" value={yil} className="form-control py-1" max={2025}
                                   onChange={(e) => setYil(e.target.value)} name="yil"/>
                        </div>
                    </div>
                    <br/>


                    <div className="row">
                        <div className="col-12">
                            <label className="form-label mb-1">ИЗОҲ: </label><br/>
                            <input type="text" value={izoh} className="form-control py-1"
                                   onChange={(e) => setIzoh(e.target.value)} name="izoh"/>
                        </div>
                    </div>


                </Modal.Body>

                <Modal.Footer className="alert-info">
                    <Button variant="primary" onClick={handleSubmitModal}>
                        {isNew ? 'Яратиш' : 'Ўзгартириш'}
                    </Button>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Bekor qilish
                    </Button>
                </Modal.Footer>

            </Modal>

            {/*<pre>{JSON.stringify(items, null, 2)}</pre>*/}
        </>
    );
}

export default Clients;
import React, {useEffect, useState} from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import {paginate} from "../Components/paginate";
import Pagination from "../Components/pagination";
import {Slide} from 'react-awesome-reveal';
import Container from "@mui/material/Container";
import dateFormat from "dateformat";

const pageSize = 10;

function HistoryPages() {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [qidirSuz, setQidirSuz] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        async function start() {
            await getItemsHistoryArrays();
        }

        start().then();
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    const getItemsHistoryArrays = async () => {
        setLoading(true);
        await axios.get('history-all')
            .then((result) => {
                setItems(result.data);
            })
            .catch((err) => {
                if (err.response.status === 481) {
                    Swal.fire('XATO!', err.response.data.message, 'error');
                } else if (err.response.status === 450) {
                    Swal.fire('XATO!', err.response.data.message, 'error');
                } else {
                    console.log(err.response);
                    Swal.fire('XATO!', 'Хатолик мавжуд. Админга хабар беринг!', 'error');
                }
                setLoading(false);
            })
        setLoading(false);
    }


    ////////////////////////////////SEARCH AND PAGINATED///////////////////////////////////////////////////////

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const dataSearched = items.filter((el) => {
        if (qidirSuz === "") {
            return true
        } else {
            return (el.kontragent.toLowerCase().includes(qidirSuz.toLowerCase()) || el.inn.toString().includes(qidirSuz.toLowerCase()))
        }
    });

    const count = dataSearched.length;
    const paginated = paginate(dataSearched, currentPage, pageSize);
    const rowNumber = (currentPage - 1) * pageSize;

    return (
        <>
            <Slide>
                <Container component="main" maxWidth="lg" className="shadow-lg rounded-3 pt-3">
                    <div className="row alert alert-danger p-2">
                        <div className="col-sm-12 text-sm-left">
                            <h5>History lists</h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-end">
                            <input className="form-control-sm btn-outline-info mb-1 ms-1" type="search"
                                   onChange={(event) => {
                                       setQidirSuz(event.target.value);
                                       setCurrentPage(1);
                                   }} placeholder="Search - Контрагент ёки ИНН"/>
                        </div>
                    </div>

                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-grow text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : ('')}

                    <div className="row mt-2">
                        <div className="col-12">
                            <table className="table table-hover table-bordered table-responsive d-md-table"
                                   style={{fontSize: 10}}>
                                <thead className="alert alert-success">
                                <tr className="text-center" style={{fontSize: 11}}>
                                    <th className="align-middle">№</th>
                                    <th className="align-middle">Контрагент</th>
                                    <th className="align-middle">ИНН</th>
                                    <th className="align-middle">Йил</th>
                                    <th className="align-middle">Сана</th>
                                    <th className="align-middle">IP Адрес</th>
                                    <th className="align-middle">MAC Адрес</th>
                                    <th className="align-middle">Комп.</th>
                                    <th className="align-middle">User</th>
                                    <th className="align-middle">File</th>
                                    <th className="align-middle">Version</th>
                                </tr>
                                </thead>
                                <tbody style={{fontSize: 12}}>
                                {
                                    paginated.map((el, i) => (
                                        <tr key={i} className={el.dostup === "False" ? "text-danger" : "text-dark"}>
                                            <td className="text-center align-middle">{rowNumber + i + 1}</td>
                                            <td className="text-start align-middle" title={el.dostup}>{el.kontragent}</td>
                                            <td className="text-center align-middle">{el.inn}</td>
                                            <td className="text-center align-middle">{el.yil}</td>
                                            <td className="text-center align-middle">{dateFormat(el.kiritilgan_sana, "dd.mm.yyyy HH:MM:ss")}</td>
                                            <td className="text-center align-middle">{el.ip_adress}</td>
                                            <td className="text-center align-middle">{el.mac_adress}</td>
                                            <td className="text-center align-middle">{el.comp_name}</td>
                                            <td className="text-center align-middle">{el.user_name}</td>
                                            <td className="text-center align-middle text-wrap text-break" style={{fontSize: 9, width:150}}>{el.file_adress}</td>
                                            <td className="text-center align-middle">{el.excel_versiya}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>

                                <tfoot className="alert-success">
                                <tr className="text-center">
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                                </tfoot>
                            </table>
                            <Pagination
                                countItems={count}
                                pageSize={pageSize}
                                onPageChange={handlePageChange}
                                currentPage={currentPage}
                            />
                        </div>
                    </div>
                </Container>
            </Slide>
            {/*<pre>{JSON.stringify(items, null, 2)}</pre>*/}
        </>
    );
}

export default HistoryPages;
import _ from "lodash";
import React from "react"; // underscore

const Pagination = ({countItems, pageSize, onPageChange, currentPage}) => {
    const pageCount = Math.ceil(countItems / pageSize);
    if (pageCount === 1) return null;
    const pages = _.range(1, pageCount + 1);
    // console.log({
    //     countItems: countItems,
    //     pageSize: pageSize,
    //     pageCount: pageCount,
    //     currentPage: currentPage,
    //     pages: pages
    // })
    let yangiSahifa = [];
    if (pageCount > 3) {
        if (currentPage === 1) {
            yangiSahifa.push(1);
            yangiSahifa.push(2);
            yangiSahifa.push(3);
        } else if (currentPage > 1 && currentPage < pageCount) {
            yangiSahifa.push(currentPage - 1);
            yangiSahifa.push(currentPage);
            yangiSahifa.push(currentPage + 1);
        } else {
            yangiSahifa.push(currentPage - 2);
            yangiSahifa.push(currentPage - 1);
            yangiSahifa.push(currentPage);
        }
    } else {
        yangiSahifa = pages;
    }

    let lastPage = 0;
    if (currentPage > 1) {
        lastPage = currentPage - 1;
    } else {
        lastPage = 1;
    }

    let nextPage = 0;
    if (currentPage < pageCount) {
        nextPage = currentPage + 1;
    } else {
        nextPage = pageCount;
    }

    return (
        <ul className='pagination' style={{height: 39}}>
            <button className="btn btn-outline-info btn-sm p-2 me-1"
                    onClick={() => onPageChange(1)}>Boshiga
            </button>
            <button className="btn btn-outline-info btn-sm p-2 me-1"
                    onClick={() => onPageChange(lastPage)}>&laquo;&laquo;</button>
            {yangiSahifa.map((page) => (
                <li key={page} className={`page-item ${currentPage === page && " active"}`}>

                    {
                        currentPage === page ? (
                            <button className={`btn btn-info btn-sm p-2`}
                                    type="submit"
                                    onClick={() => onPageChange(page)}>{page}</button>
                        ):(
                            <button className={`btn btn-${currentPage === page && "outline-"}info btn-sm p-2`}
                                    type="submit"
                                    onClick={() => onPageChange(page)}>{page}</button>
                        )
                    }

                </li>
            ))}
            <button className="btn btn-outline-info btn-sm p-2 ms-1"
                    onClick={() => onPageChange(nextPage)}>&raquo;&raquo;</button>
            <button className="btn btn-outline-info btn-sm p-2 ms-1"
                    onClick={() => onPageChange(pageCount)}>Oxiriga
            </button>
            <li className="page-item ms-2 pt-2"
                style={{fontSize: 12}}>{`Listlar soni ${pageCount} ta, satrlar soni ${countItems} ta`} </li>
        </ul>
    );
};

export default Pagination;

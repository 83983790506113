import React, {useState, useEffect} from 'react';
import axios from "axios";
import Swal from "sweetalert2";

function HomePage() {
    const [item, setItem] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        async function start() {
            await getItemRuyxat();
        }

        start().then();
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    const getItemRuyxat = async () => {
        await axios.get('get-counts-all')
            .then((result) => {
                setItem(result.data);
            })
            .catch((err) => {
                if (err.response.status === 481) {
                    Swal.fire('XATO!', err.response.data.message, 'error');
                } else if (err.response.status === 450) {
                    Swal.fire('XATO!', err.response.data.message, 'error');
                } else {
                    console.log(err.response);
                    Swal.fire('XATO!', 'Хатолик мавжуд. Админга хабар беринг!', 'error');
                }
                setLoading(false);
            })
    }


    return (
        <>
            <div className="container-sm">
                <div className="row text-center alert alert-success">
                    <div className="col-12">
                        <h3>Главный страница</h3>
                    </div>
                </div>

                <div className="row justify-content-start">
                    <div className="col-4">
                        <ol className="list-group list-group-numbered">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Контрагенты</div>
                                    Количество контрагенты
                                </div>
                                <span className="badge bg-primary rounded-pill">{item.countK}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Доступы</div>
                                    Активные программы
                                </div>
                                <span className="badge bg-primary rounded-pill">{item.countDA}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Доступы</div>
                                    Не активные программы
                                </div>
                                <span className="badge bg-primary rounded-pill">{item.countDN}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Истроии</div>
                                    Количество истории
                                </div>
                                <span className="badge bg-primary rounded-pill">{item.countH}</span>
                            </li>
                        </ol>
                    </div>
                </div>

            </div>

        </>
    );
}

export default HomePage;



